@import "/src/App.scss";

.card {
  border: none;

  .card-img-inner {
    max-width: 150px;
    max-height: 150px;
    min-height: 150px;
    overflow: hidden;
    margin: 0 auto;
    align-items: center;
    background-size: contain;
    display: flex;
    padding: 5px 5px 0px 5px;

    .card-img-top {
      vertical-align: middle;
      align-items: center;
      margin: auto auto;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .itemName {
    height: 40px;
    font-size: 16px;
    overflow: hidden;
  }

  &.line-view {
    .card-img-inner {
      min-height: 100px;
      max-height: 110px;
    }
  }
}

.cart-item {
  .card {
    font-size: smaller;
    border-radius: 0;
    border-bottom: 1px solid $gray-300;

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }

    .itemName {
      font-size: 16px;
    }
  }

  &:last-child .card {
    border-bottom: none;
  }
}
