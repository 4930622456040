@import "/src/App.scss";

.icon-new {
  color: $primary;
}

.icon-main {
  color: $primary;
  cursor: pointer;
}

.icon-bonus {
  color: #ffd700;
}

.icon-down {
  color: #b50707;
}

.icon-up {
  color: rgb(7, 80, 7);
}

.icon-info {
  color: #b16d08;
  float: left;
}

.icon-calendar {
  color: $gray-500;
}

.icon-trash {
  color: $gray-300;

  &:hover {
    color: $gray-500;
    cursor: pointer;
  }
}

.icon {
  position: relative;
  background-color: white;
  border-radius: 30px;
  padding: 1px;

  &.icon-trash {
    margin-left: 0;
  }

  &.icon-calendar {
    position: absolute;
    left: 2px;
    top: 2px;
    z-index: 1;

    &:hover {
      position: absolute;
    }
  }

  span {
    display: none;
  }

  &:hover {
    position: relative;

    span {
      font-size: 12px;
      border: #c0c0c0 1px dotted;
      color: $gray-800;
      border-radius: 4px;
      background-color: white;
      padding: 5px;
      display: block;
      z-index: 100;
      width: auto;
      position: absolute;
      text-decoration: none;
    }
  }
}

.icon-round-bg {
  color: white;
  border-radius: 30px;
  width: 22px;
  height: 22px;
  text-align: center;
  margin: 2px;

  .icon-size {
    width: 16px;
    height: 16px;
    padding: 1px;
    margin-top: -5px;
  }

  &.icon-sale {
    background-color: #ea0505;
  }

  &.icon-return {
    background-color: rgb(7, 80, 7);
  }
}

.iconBar {
  position: absolute;
  padding: 5px 10px;

  ul {
    margin-left: 8px;

    li {
      display: inline-block;
      margin-left: -8px;
    }
  }
}

.icon-list {
  ul {
    li {
      background-color: white;
      border-radius: 4px;

      &:hover {
        background-color: $gray-200;
      }
    }
  }
}

.icon-title {
  padding-left: 35px;
  line-height: 26px;
  color: white;
  font-weight: 500;
}

.icon-pic {
  float: left;
  background-color: white;
  border-radius: 20px;
}

.tag {
  padding: 2px;
  border-radius: 30px;

  .icon {
    margin-left: 0;
    padding: 0;
    float: left;
  }

  &.icon-new {
    background-color: #1297cc;
  }

  &.icon-bonus {
    background-color: #dab803;
  }

  &.icon-sale {
    background-color: #b50707;
  }
}

.bage {
  position: absolute;
  background-color: #ea0505;
  padding: 4px 5px;
  border-radius: 20px;
  color: white;
  font-size: 10px;
  min-width: 20px;
  text-align: center;
  line-height: 12px;
  top: -10px;
  right: -8px;
}
