.logo {
  width: 40px;
  height: 30px;
  background: url("./logo30.png") no-repeat;
  float: left;
}

header {
  a {
    text-decoration: none;
  }

  ul li {
    list-style: none;
    display: inline-block;
    margin-left: 30px;

    a {
      text-decoration: none;
      display: inline-flex;
      color: #000;
      font-weight: 500;
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }

  .icon {
    float: none;
  }
}
