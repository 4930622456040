.priceGroup {
  position: relative;

  .price {
    font-weight: 500;
    font-size: 18px;

    &.old {
      position: absolute;
      background-color: white;
      top: -15px;
      font-size: 14px;
      text-decoration: line-through;
    }

    &.new {
      color: #c20606;
      small { color: #c20606; }
    }
  }
}
