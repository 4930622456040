$body-bg: #eff0f0;
$body-color: black;
$input-btn-focus-box-shadow: none;
$blue: #009fe3 !default;
$modal-header-border-color: transparent;
$border-color: transparent;
$h1-font-size: 20px;
$h2-font-size: 18px;

@import "../node_modules/bootstrap/scss/bootstrap";

body {
  font-family: Roboto, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.btn {
  color: white;

  &:hover {
    color: white;
  }
}

.btn-primary {
  color: white;

  &:hover {
    color: white;
  }

  &:disabled {
    color: white;
    background-color: $gray-500;
    border-color: $gray-500;
  }

  &.disalbed {
    color: white;
    background-color: $gray-500;
    border-color: $gray-500;
  }
}

.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;

  &:hover {
    color: white;
    background-color: $gray-300;
    border-color: $gray-300;
    opacity: 1;
  }

  &.btn-del {
    background: none;
    padding: 0;
    width: 25px;
    height: 25px;
    z-index: 2;
  }
}

small {
  color: $gray-600;
}

.note {
  font-size: 12px;
  color: $gray-800;
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.dash {
  height: 1px;
  width: 100%;
  background-color: $gray-300;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.informer {
  background-color: #da600340;
  padding: 10px;

  .text {
    padding-left: 35px;
    line-height: 25px;
    color: #905602;
  }

  &.warning {
    background-color: #ffc0cb;

    .text {
      color: #631304f1;
    }

    .icon-info {
      color: #631304f1;
    }
  }
}

.term {
  font-weight: normal;
  float: left;
  padding: 4px 3px 0 0;
}

.tab-content {
  background-color: white;
  border: 1px solid #dee2e6;
  border-top: none;
  border-radius: 0 4px 4px 4px;
}

.nav-tabs {
  .nav-link {
    &.active {
      background-color: white;
    }
  }
}

.green {
  color: green;
}

.red {
  color: #b50707;
}

.link {
  &:hover {
    cursor: pointer;
    color: $primary;
  }
}

.form-check {
  padding-left: 35px;

  &:hover {
    cursor: pointer;
  }

  .form-check-label {
    line-height: 28px;
    display: block;

    &:hover {
      cursor: pointer;
    }
  }

  .form-check-input {
    width: 20px;
    height: 20px;
    margin-left: -35px;
  }
}

.empty {
  color: $gray-300;
  align-items: center;
  text-align: center;
  &.nopage { font-size: 80px; }
  &.wrong { color: #b50707; }

  &.text {
    color: $gray-800;
    font-size: 20px;
  }
}

.form-control {
  color: $gray-800;
  background-color: white;
  &:focus{
    color: $gray-800;
    background-color: $gray-100;
  }
}

.modal-body{
  img{
    width: 100%;
  }
}

.form-group{
  .icon{
    position: absolute;
    right: 12px;
    top: 8px;
  }
}

.navbar-toggler{
  :focus{
    box-shadow: none!important;
  }
}
