.navbar-light {
  .navbar-toggler {
    background-color: white;
    :focus {
      box-shadow: none;
    }
  }
  .sidebar {
    background-color: white;
    border-radius: 0.25rem;
    &.navbar-nav {
      .list-group-item {
        padding: 0;
        cursor: pointer;
        .icon {
          float: right;
        }
        a {
          text-decoration: none;
          color: black;
          padding: 0.75rem 1rem;
          display: block;
        }
        &.active {
          a {
            color: white;
          }
        }
      }
      .nav-link {
        color: #343a40;
        &.active {
          z-index: 2;
          color: #fff;
          background-color: #009fe3;
          border-color: #009fe3;
          a {
            color: white;
          }
        }
      }
    }
  }
}
