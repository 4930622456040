.brand-logo {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  background: url("./log200.png") 50% no-repeat;
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: #009fe380 !important;
}

.css-26l3qy-menu {
  z-index: 2 !important;
}

.form-group {
  position: relative;
}

.auth-lable {
  position: absolute;
  font-size: smaller;
  background-color: white;
  color: gray;
  top: -4px;
  left: 5px;
  padding: 0 5px;
  z-index: 1;
  line-height: 10px;
}
