@import "/src/App.scss";

.btnGroup {

  position: relative;
  height: 30px;
  width: 100%;
  transition: all 1s ease-out;

  .btn {
    width: 30px;
    height: 30px;
    background-color: $gray-300;
    border: 1px solid $gray-400;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: gray;

    &:hover {
      background-color: $gray-500;
      color: white;
    }

    &.minus {
      left: 0;
      opacity: 1;
    }

    &.plus {
      right: 0;
    }
  }

  .icon-btnGroup {
    width: 20px;
    height: 20px;
  }

  .form-control {
    padding: 2px 20px;
    opacity: 1;

    &:focus {
      border-color: $gray-400;
    }
  }
}
